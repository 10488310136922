<template>
	<div class="card">
		<p class="title">账号设置</p>
		<p class="line"></p>
		<div class="info">
			<p>基本信息</p>
			<el-form label-position="top" label-width="80px" :model="form">
				<el-form-item label="头像">
					<div class="header">
						<img src="@/assets/imgs/avactor2.png" alt="" />
					</div>
				</el-form-item>
				<el-form-item label="名称">
					<el-input v-model="form.loginName" disabled></el-input>
				</el-form-item>
				<el-form-item label="联系电话">
					<el-input v-model="form.phoneNumber" disabled></el-input>
				</el-form-item>
				<el-form-item label="账号">
					<el-input v-model="form.loginName" disabled></el-input>
				</el-form-item>
				<el-form-item label="密码">
					<el-input value="******" disabled></el-input>
					<span class="update" @click="modifyPwd">修改密码</span>
				</el-form-item>
				<el-form-item label="帐号区域">
					<el-input v-model="form.local" disabled></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="form.description" disabled type="textarea" resize="none" autosize></el-input>
				</el-form-item>
			</el-form>
		</div>
		<ForgetPwd ref="password"></ForgetPwd>
	</div>
</template>
<script>
import { Session } from '@/utils/storage.js';
export default {
	components: {
		ForgetPwd: () => import('./forgetPwd.vue'),
	},
	data() {
		return {
			form: {},
		};
	},
	mounted() {
		this.getUserInfo();
	},
	methods: {
		// 修改密码
		modifyPwd() {
			this.$refs.password.dialogVisible = true;
		},
		getUserInfo() {
			this.$http
				.get(this.api['UserProfiles#load'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.form = res.data.collection[0];
						this.form.local = res.data.collection[0].chinaRegionDtoList?.map((item) => item.title).join('-') || '-';
						// 存储用户信息到浏览器缓存
						const userInfos = {
							...res.data.collection[0],
						};
						Session.set('userInfo', userInfos);
					}
				})
				.catch((e) => {});
		},
	},
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 800px) {
	.el-input {
		width: 280px !important;
	}
	.info {
		width: 350px !important;
		padding-left: 5px;
		margin-top: 5px !important;
	}
}
.card {
	background-color: #fff;
	border-radius: 2px;
	margin: 16px;
	// padding-left: 38px;
	padding-top: 18px;
	overflow: auto;
	.title {
		font-size: 16px;
		color: #1db9b1;
		padding-bottom: 18px;
		display: inline-block;
		width: 66px;
		border-bottom: 3px solid #1db9b1;
		margin-left: 38px;
	}
	.line {
		width: 100%;
		height: 2px;
		background-color: #f3f3f3;
	}
	.info {
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 452px;
		margin: 60px auto;

		p {
			font-size: 16px;
			margin-bottom: 8px;
		}
		.header {
			display: flex;
			color: #1db9b1;
			align-items: flex-end;
			img {
				width: 74px;
				height: 74px;
				border-radius: 50%;
				margin-right: 8px;
			}
			span {
				cursor: pointer;
			}
		}
		.update {
			color: #1db9b1;
			margin-left: 8px;
			cursor: pointer;
		}
		/deep/ .el-form-item__label {
			color: #999;
		}
		/deep/ .el-input,
		/deep/ .el-textarea__inner {
			width: 388px;
		}
	}
}
</style>